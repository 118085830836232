//Libraries
import React, { FunctionComponent } from 'react'
import Img from 'gatsby-image'
import cs from 'classnames'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

//Context
import { FontSizeProvider } from '../../context/fontSizeContext'

//Types
import { SeniorCenterInfoTypes } from '../z_components-types'

//Styles
import styles from './css/seniorCenterInfo.module.scss'

//Components
import IconWithText from '../free/iconWithText'
import GoogleMap from '../free/googleMap'
import Header from '../header/header'
import Layout from '../layout/layout'
import Footer from '../footer/footer'

const SeniorCenterInfo: FunctionComponent<SeniorCenterInfoTypes> = ({
  data,
}) => {
  const { markdownRemark } = data
  const location = markdownRemark.frontmatter

  const openPrintDialog: () => void = () => {
    window.print()
  }

  return (
    <FontSizeProvider>
      <Helmet htmlAttributes={{lang: 'en'}}>
        <meta charSet="utf-8" />
        <title>OOA - {location.name}</title>
        <link
          rel="canonical"
          href={typeof window !== 'undefined' ? window.location.href : ''}
        />
        <meta
          name="description"
          content={`Contact information about ${location.name}`}
        />
      </Helmet>

      <Header />

      <Layout>
        <div className={styles.centerContainer}>
          <div className={styles.centerIdentity}>
            <i
              onClick={openPrintDialog}
              className={cs('ri-printer-fill', styles.printButton)}
            ></i>
            <Img
              fluid={location.img.childImageSharp.fluid}
              className={styles.centerImg}
              alt="Picture of Senior Center"
            />

            <div className={styles.infoContainer}>
              <h2 className={styles.centerTitle}>{location.name}</h2>
              <p className={styles.siteManager}>
                <strong>Site manager:</strong> {location.siteManager}
              </p>
              <IconWithText
                icon="ri-home-4-line"
                text={[location.address, location.city]}
              />

              <IconWithText icon="ri-mail-line" emails={[location.email]} />

              <IconWithText
                icon="ri-phone-line"
                tels={[
                  location.fax && location.fax.toLowerCase() === 'same'
                    ? `Phone and Fax: ${location.phone}`
                    : `Phone: ${location.phone}`,
                  location.fax &&
                    location.fax.toLowerCase() !== 'same' ?
                    `Fax: ${location.fax}` : '',
                ]}
              />

              <IconWithText
                icon="ri-global-line"
                links={[
                  {
                    url: location.website,
                    urlText: 'Website',
                  }
                ]}
              />

              <IconWithText
                icon="ri-facebook-box-line"
                links={[
                  {
                    url: location.facebook,
                    urlText: 'Facebook',
                  }
                ]}
              />
            </div>
          </div>

          <div className={cs('table-responsive')}>
            <table className="table">
              <thead className="thead-light">
                <tr>
                  <th></th>
                  <th scope="col">MON</th>
                  <th scope="col">TUE</th>
                  <th scope="col">WED</th>
                  <th scope="col">THUR</th>
                  <th scope="col">FRI</th>
                  <th scope="col">SAT</th>
                  <th scope="col">SUN</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">Hours</th>
                  <td>{location.mondayHours}</td>
                  <td>{location.tuesdayHours}</td>
                  <td>{location.wednesdayHours}</td>
                  <td>{location.thursdayHours}</td>
                  <td>{location.fridayHours}</td>
                  <td>{location.saturdayHours}</td>
                  <td>{location.sundayHours}</td>
                </tr>
                <tr>
                  <th scope="row">Lunch</th>
                  <td>
                    {location.mondayLunchHours
                      ? location.mondayLunchHours
                      : 'N/A'}
                  </td>
                  <td>
                    {location.tuesdayLunchHours
                      ? location.tuesdayLunchHours
                      : 'N/A'}
                  </td>
                  <td>
                    {location.wednesdayLunchHours
                      ? location.wednesdayLunchHours
                      : 'N/A'}
                  </td>
                  <td>
                    {location.thursdayLunchHours
                      ? location.thursdayLunchHours
                      : 'N/A'}
                  </td>
                  <td>
                    {location.fridayLunchHours
                      ? location.fridayLunchHours
                      : 'N/A'}
                  </td>
                  <td>
                    {location.saturdayLunchHours
                      ? location.saturdayLunchHours
                      : 'N/A'}
                  </td>
                  <td>
                    {location.sundayLunchHours
                      ? location.sundayLunchHours
                      : 'N/A'}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={styles.notes} style={{ marginTop: '16px' }}>
            {location.volunteerInfo?.contact && (
              <>
                <h2 id="volunteer" className={styles.centerTitle}>Volunteer Opportunity</h2>
                { location.volunteerInfo?.contact && (
                  <>
                    <p>If you'd like to volunteer at this Senior Center, please reach out to the conact below</p>
                    <IconWithText
                      icon="ri-user-line"
                      text={[location.volunteerInfo.contact]}
                    />
                  </>
                )}
                { location.volunteerInfo.email && (
                  <IconWithText
                    icon="ri-mail-line"
                    emails={[location.volunteerInfo.email]}
                  />
                )}
                { location.volunteerInfo.phone && (
                  <IconWithText
                    icon="ri-phone-line"
                    tels={[location.volunteerInfo.phone]}
                  />
                )}
                <p className={styles.volunteerBody} dangerouslySetInnerHTML={{ __html: location.volunteerInfo.description }}></p>
              </>
            )}
          </div>
          <div className={styles.notes} style={{ marginTop: '16px' }}>
            <h2 className={styles.centerTitle}>
              {data.markdownRemark.html && 'Additional Information'}
            </h2>
            <span
              dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
            ></span>
          </div>
        </div>
        <GoogleMap
          lat={location.lat}
          lng={location.lng}
          address={`${location.address}\u00A0${location.city}`}
        />
      </Layout>
      <Footer />
    </FontSizeProvider>
  )
}

export default SeniorCenterInfo

export const SeniorCenterQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        address
        city
        email
        fax
        website
        facebook
        img {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        lat
        lng
        mondayHours
        mondayLunchHours
        name
        phone
        siteManager
        thursdayHours
        thursdayLunchHours
        tuesdayHours
        tuesdayLunchHours
        wednesdayHours
        wednesdayLunchHours
        fridayHours
        fridayLunchHours
        saturdayHours
        saturdayLunchHours
        sundayHours
        sundayLunchHours
        volunteerInfo {
          contact
          phone
          email
          description
        }
      }
    }
  }
`
